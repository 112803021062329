import { Box, Grid } from "@mui/material";
import { Outlet, Route, Routes } from "react-router-dom";
import Works from "./Works";
import Aboutme from "./Aboutme";

const Main = () => {
  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Routes>
          <Route path="/works" element={<Works />} />
          <Route path="/about" element={<Aboutme />} />
        </Routes>
        <Outlet />
      </Grid>
    </Box>
  );
};

export default Main;
