import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  IconButton,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import settings from "../assets/settings.json";

const Appbar = ({ darkMode, setDarkMode }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Toolbar>
      <Box sx={{ flexGrow: 0 }}>
        <IconButton onClick={handleOpenUserMenu}>
          <Avatar alt="riky_avatar" src="" />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem
              key={setting}
              onClick={handleCloseUserMenu}
              component={Link}
              to={setting.url}
            >
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Typography variant="subtitle1">Riccardo Francesco Croce</Typography>
      <div style={{ flexGrow: 1 }} />
      <IconButton onClick={() => setDarkMode(!darkMode)}>
        {darkMode ? <LightModeOutlined /> : <DarkModeOutlined />}
      </IconButton>
    </Toolbar>
  );
};

export default Appbar;
