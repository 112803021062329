import { createTheme } from "@mui/material";

export const theme = (mode) => {
  return createTheme({
    palette: {
      mode: mode ? "dark" : "light",
      background: {
        default: mode ? "#181a1b" : "#ffffff",
        paper: mode ? "#181a1b" : "#fcfcfc",
      },
    },
    typography: {
      fontFamily: ["Nunito"],
      h3: { transition: "color 200ms ease-in-out" },
      subtitle1: { transition: "color 200ms ease-in-out" },
    },
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            borderRadius: "25px",
            width: "2.5rem",
            height: "2.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            transition: "color 200ms ease-in-out",
            color: mode ? "#ffffff" : "#000000",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            transition:
              "background-color 200ms ease-in-out, color 100ms ease-in-out",
          },
        },
      },
    },
  });
};
