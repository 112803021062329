import { Box, Grid, Typography } from "@mui/material";

const Aboutme = () => {
  return (
    <Box>
      <Grid sx={{ p: 2 }}>
        <Typography variant="h3">Work Experience</Typography>
      </Grid>
      <Grid justifyContent="center" container>
        TEST
      </Grid>
    </Box>
  );
};

export default Aboutme;
