import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./style";
import Main from "./components/Main";
import AppBar from "./components/Appbar";
import { Box } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <ThemeProvider theme={theme(darkMode)}>
      <CssBaseline />
      <BrowserRouter>
        <Box sx={{ height: "100%" }}>
          <AppBar darkMode={darkMode} setDarkMode={setDarkMode} />
          <Main />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
