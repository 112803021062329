import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import works from "../assets/works.json";
import React from "react";

const Works = () => {
  const worksItems = works.map((work) => (
    <Card sx={{ maxWidth: 500, m: 2 }} variant="outlined">
      <ListItem sx={{ p: 0 }}>
        <Grid
          container
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">{work.name}</Typography>
            <Icon
              sx={{
                backgroundColor: work.icon.backgroundColor,
                color: work.icon.color,
              }}
            >
              {work.icon.name}
            </Icon>
          </Box>
          <Typography variant="h5">{work.role}</Typography>
          <Typography variant="h6">{`${work.to} - ${work.from}`}</Typography>
          <Divider
            sx={{
              marginTop: 2,
              marginBottom: 2,
              borderColor: work.icon.backgroundColor,
            }}
          />
          <Typography variant="body">{work.fullDescription}</Typography>
          <Box>
            <List>
              {work.points.map((point) => (
                <ListItem
                  alignItems="flex-start"
                  sx={{ p: 0 }}
                  key={point.iconName}
                >
                  <ListItemIcon sx={{ marginTop: 0 }}>
                    <Icon>{point.iconName}</Icon>
                  </ListItemIcon>
                  <ListItemText>{point.description}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          <br />
          <Box>
            {work.tags.map((tag) => (
              <Chip
                key={tag.name}
                label={tag.name}
                component="a"
                href={tag.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  backgroundColor: tag.backgroundColor,
                  color: tag.color,
                  mr: 1,
                  mt: 1,
                }}
              />
            ))}
          </Box>
        </Grid>
      </ListItem>
    </Card>
  ));
  return (
    <Box>
      <Grid sx={{ p: 2 }}>
        <Typography variant="h3">Work Experience</Typography>
      </Grid>
      <Grid justifyContent="center" container>
        {worksItems}
      </Grid>
    </Box>
  );
};

export default Works;
